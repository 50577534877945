// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../components/Paragraph/P.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Dropdown from "../../forms/Dropdown/Dropdown.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Textarea from "../../forms/Textarea/Textarea.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as NoBidModalScss from "./NoBidModal.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = NoBidModalScss;

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "ErrorOnFetch") {
      return {
              input: state.input,
              providers: state.providers,
              isFetching: false,
              isFetchError: true
            };
    } else {
      return {
              input: Provider.Simple.NoBidInput.empty(),
              providers: state.providers,
              isFetching: state.isFetching,
              isFetchError: state.isFetchError
            };
    }
  }
  if (action.TAG !== "FetchProviders") {
    return {
            input: action._0,
            providers: state.providers,
            isFetching: state.isFetching,
            isFetchError: state.isFetchError
          };
  }
  var res = action._0;
  var provider = res.providers.length === 1 ? Belt_Array.get(res.providers, 0) : undefined;
  var providerId = provider !== undefined ? Caml_option.some(provider.id) : undefined;
  var input = state.input;
  return {
          input: {
            message: input.message,
            providerId: providerId
          },
          providers: res.providers,
          isFetching: false,
          isFetchError: state.isFetchError
        };
}

function NoBidModal(props) {
  var projectId = props.projectId;
  var close = props.close;
  var match = React.useReducer(reducer, {
        input: Provider.Simple.NoBidInput.empty(),
        providers: [],
        isFetching: true,
        isFetchError: false
      });
  var dispatch = match[1];
  var state = match[0];
  var fetchProviders = function () {
    $$Promise.wait(Api.fetchProviders(projectId), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "FetchProviders",
                          _0: x._0
                        });
            }
            SentryLogger.error1({
                  rootModule: "NoBidModal",
                  subModulePath: /* [] */0,
                  value: "make",
                  fullPath: "NoBidModal.make"
                }, "NoBidModal::FetchProviders::Error", [
                  "Error",
                  x._0
                ]);
            dispatch("ErrorOnFetch");
          }));
  };
  var sendNoBid = function () {
    $$Promise.wait(Api.noBidProvider(projectId, state.input), (function (x) {
            if (x.TAG === "Ok") {
              return Url.visit(Routes_Project.Proposal.show(projectId, x._0.id));
            } else {
              return SentryLogger.error1({
                          rootModule: "NoBidModal",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "NoBidModal.make"
                        }, "NoBidModal::NoBidProvider::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
    dispatch("Send");
    close();
  };
  React.useEffect((function () {
          fetchProviders();
        }), []);
  var match$1 = state.isFetching;
  var match$2 = state.isFetchError;
  var tmp;
  if (match$1) {
    tmp = JsxRuntime.jsx(P.make, {
          children: "Fetching providers. Please wait..."
        });
  } else if (match$2) {
    tmp = JsxRuntime.jsx(P.make, {
          children: "Error on fetching providers. Please try again later."
        });
  } else {
    var match$3 = state.providers.length;
    if (match$3 !== 0) {
      switch (props.userRole) {
        case "Provider" :
            tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                  children: [
                    JsxRuntime.jsx("p", {
                          children: "Send the message and click the button to confirm.",
                          className: css.description
                        }),
                    JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("label", {
                                  children: "Message to the Client",
                                  className: css.label
                                }),
                            JsxRuntime.jsx(Textarea.make, {
                                  id: "no-bid--message",
                                  value: state.input.message,
                                  rows: 4,
                                  placeholder: "Describe the reason",
                                  className: css.message,
                                  onChange: (function ($$event) {
                                      var init = state.input;
                                      dispatch({
                                            TAG: "Update",
                                            _0: {
                                              message: $$event.target.value,
                                              providerId: init.providerId
                                            }
                                          });
                                    })
                                })
                          ],
                          className: css.field
                        })
                  ]
                });
            break;
        case "User" :
        case "Visitor" :
            tmp = null;
            break;
        default:
          var id = state.input.providerId;
          var tmp$1;
          if (id !== undefined) {
            var id$1 = Caml_option.valFromOption(id);
            tmp$1 = Belt_Array.map(state.providers, (function (provider) {
                    if (Caml_obj.equal(provider.id, id$1)) {
                      return JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("img", {
                                          className: css.providerLogo,
                                          src: provider.logo
                                        }),
                                    JsxRuntime.jsx("label", {
                                          children: provider.name,
                                          className: css.option
                                        })
                                  ],
                                  className: css.logoNamePair
                                }, ID.toString(id$1));
                    } else {
                      return null;
                    }
                  }));
          } else {
            tmp$1 = "Select a Provider";
          }
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx("p", {
                        children: "Select the provider that you don't want to make a bid and click the button to confirm.",
                        className: css.description
                      }),
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("label", {
                                children: "Provider",
                                className: css.label
                              }),
                          JsxRuntime.jsxs(Dropdown.make, {
                                children: [
                                  JsxRuntime.jsx(Dropdown.Trigger.make, {
                                        className: css.dropdownTrigger,
                                        iconSize: "XS",
                                        iconColor: "Gray",
                                        children: tmp$1
                                      }),
                                  JsxRuntime.jsx(Dropdown.Body.make, {
                                        position: {
                                          TAG: "Below",
                                          _0: "LeftEdge"
                                        },
                                        className: css.dropdownBody,
                                        children: Belt_Array.map(state.providers, (function (provider) {
                                                var id = "no-bid--provider-" + ID.toString(provider.id);
                                                return JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsxs(Control.make, {
                                                                  className: css.logoNamePair,
                                                                  onClick: (function (param) {
                                                                      var init = state.input;
                                                                      dispatch({
                                                                            TAG: "Update",
                                                                            _0: {
                                                                              message: init.message,
                                                                              providerId: Caml_option.some(provider.id)
                                                                            }
                                                                          });
                                                                    }),
                                                                  children: [
                                                                    JsxRuntime.jsx("img", {
                                                                          className: css.providerLogo,
                                                                          src: provider.logo
                                                                        }),
                                                                    JsxRuntime.jsx("label", {
                                                                          children: provider.name,
                                                                          className: css.option
                                                                        })
                                                                  ]
                                                                }),
                                                            className: css.dropdownBodyRow
                                                          }, id);
                                              }))
                                      })
                                ]
                              })
                        ],
                        className: css.field
                      }),
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("label", {
                                children: "Message to the Client",
                                className: css.label
                              }),
                          JsxRuntime.jsx(Textarea.make, {
                                id: "no-bid--message",
                                value: state.input.message,
                                rows: 4,
                                placeholder: "Describe the reason",
                                className: css.message,
                                onChange: (function ($$event) {
                                    var init = state.input;
                                    dispatch({
                                          TAG: "Update",
                                          _0: {
                                            message: $$event.target.value,
                                            providerId: init.providerId
                                          }
                                        });
                                  })
                              })
                        ],
                        className: css.field
                      })
                ]
              });
      }
    } else {
      tmp = JsxRuntime.jsx(P.make, {
            children: "No providers found"
          });
    }
  }
  var match$4 = state.providers.length;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("h2", {
                                    children: props.title
                                  }),
                              tmp,
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      match$4 !== 0 ? (
                                          match$4 !== 1 ? JsxRuntime.jsx(Button.make, {
                                                  size: "SM",
                                                  color: "Teal",
                                                  disabled: state.input.message === "",
                                                  visuallyDisabled: state.input.message === "" || state.input.providerId === undefined,
                                                  className: css.sendButton,
                                                  onClick: (function (param) {
                                                      sendNoBid();
                                                    }),
                                                  children: "No Bid"
                                                }) : JsxRuntime.jsx(Button.make, {
                                                  size: "SM",
                                                  color: "Teal",
                                                  disabled: state.input.message === "",
                                                  visuallyDisabled: state.input.message === "",
                                                  className: css.sendButton,
                                                  onClick: (function (param) {
                                                      var uniqueProvider = Belt_Array.get(state.providers, 0);
                                                      if (uniqueProvider === undefined) {
                                                        return ;
                                                      }
                                                      var init = state.input;
                                                      dispatch({
                                                            TAG: "Update",
                                                            _0: {
                                                              message: init.message,
                                                              providerId: Caml_option.some(uniqueProvider.id)
                                                            }
                                                          });
                                                      sendNoBid();
                                                    }),
                                                  children: "No Bid"
                                                })
                                        ) : null,
                                      JsxRuntime.jsx(Button.make, {
                                            size: "SM",
                                            color: "Gray",
                                            className: css.cancelButton,
                                            onClick: (function (param) {
                                                close();
                                              }),
                                            children: "Cancel"
                                          })
                                    ],
                                    className: css.buttons
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: css.modal
                    })
              ],
              className: css.modalContainer
            });
}

var make = NoBidModal;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */

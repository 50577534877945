// extracted by mini-css-extract-plugin
export var buttons = "NoBidModal__buttons__fGC1v";
export var clientMessage = "NoBidModal__clientMessage__n30GO";
export var closeOverlayButton = "NoBidModal__closeOverlayButton__CHQX3";
export var column = "NoBidModal__column__CZ3yX";
export var description = "NoBidModal__description__sUjqI";
export var dropdownBody = "NoBidModal__dropdownBody__Y_qWR";
export var dropdownBodyRow = "NoBidModal__dropdownBodyRow__WpfRv";
export var errorContainer = "NoBidModal__errorContainer__Y20ch";
export var errors = "NoBidModal__errors__AUhLS";
export var field = "NoBidModal__field__pTa9P";
export var flex = "NoBidModal__flex__D1FZm";
export var flexColumn = "NoBidModal__flexColumn__T7Bci";
export var gap1 = "NoBidModal__gap1__uFRzp";
export var gap2 = "NoBidModal__gap2__Cg__5";
export var gap3 = "NoBidModal__gap3__GV5SR";
export var gap4 = "NoBidModal__gap4__DbFyH";
export var gap5 = "NoBidModal__gap5__kYozX";
export var label = "NoBidModal__label__co4at";
export var logoNamePair = "NoBidModal__logoNamePair__oenZN";
export var modal = "NoBidModal__modal__CBOBg";
export var modalContainer = "NoBidModal__modalContainer__XQiO4";
export var modalContents = "NoBidModal__modalContents__rmADA";
export var modalOverlay = "NoBidModal__modalOverlay__HBRkU";
export var option = "NoBidModal__option__sC2jA";
export var providerLogo = "NoBidModal__providerLogo__U47sl";
export var row = "NoBidModal__row__Oew8I";